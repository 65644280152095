import { Button, TextField } from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import db, { uploadDocumentWithName } from "../../firebase";
import { toast } from "react-toastify";
import ReactSelect from "react-select";
import { getAuthFromLocal } from "../../utils/storage";

type SelectOption = {
  value: string;
  label: string;
};

const Admin = () => {
  const [message, setMessage] = useState("");
  const [isAttachmentUpload, setIsAttachmentUpload] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [filename, setFilename] = useState("");
  const [users, setUsers] = useState<any>([]);
  const [buyer, setBuyer] = useState<any>("");
  const user = getAuthFromLocal();

  const [targetPerson, setTargetPerson] = useState("");
  const [targetDate, setTargetDate] = useState("");

  const isKAM = user?.role === "KAM";

  useEffect(() => {
    fetchExistingTickets();
  }, []);

  useEffect(() => {
    const fetchUsersAndKams = async () => {
      const usersCollection = collection(db, "users");

      try {
        if (isKAM) {
          // Query to get users with role "USER"
          const usersQuery = query(
            usersCollection,
            where("phoneNumber", "==", user.phoneNumber)
          );
          const userDocs = await getDocs(usersQuery);
          const buyers = userDocs.docs[0].data()?.buyers;
          const usersList: any = buyers.map((name: string) => ({
            value: name,
            label: name,
          }));

          setUsers(usersList);
        } else {
          // Query to get users with role "USER"
          const usersQuery = query(
            usersCollection,
            where("role", "==", "USER")
          );
          const userDocs = await getDocs(usersQuery);
          const usersList: any = userDocs.docs.map((doc) => ({
            value: doc.id,
            label: doc.data().username,
            ...doc.data(),
          }));

          setUsers(usersList);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUsersAndKams();
  }, []);

  const usedTicketNumbers = new Set();

  async function fetchExistingTickets() {
    const querySnapshot = await getDocs(collection(db, "messages"));
    querySnapshot.forEach((doc) => {
      const ticket = doc.data()?.ticket;
      if (ticket) {
        usedTicketNumbers.add(ticket); // Add existing ticket to the Set
      }
    });
  }

  const generateTicketNumber = async () => {
    try {
      const counterRef = doc(db, "counters", "ticketNumber");
      const counterSnap = await getDoc(counterRef);

      if (counterSnap.exists()) {
        // Increment the counter atomically
        const currentNumber = counterSnap.data().current || 0;
        const newNumber = currentNumber + 1;

        // Update the counter in Firestore
        await updateDoc(counterRef, { current: increment(1) });

        // Format the ticket number (e.g., T-0001, T-0002)
        const formattedTicket = `T-${newNumber.toString().padStart(4, "0")}`;
        return formattedTicket;
      } else {
        // Initialize the counter if it doesn't exist
        await setDoc(counterRef, { current: 1 });
        return "T-0001";
      }
    } catch (error) {
      console.error("Error generating ticket number:", error);
      throw new Error("Failed to generate ticket number.");
    }
  };

  const handleSendMessage = async () => {
    if (message) {
      const ticketNumber = await generateTicketNumber();
      try {
        const docRef = await addDoc(collection(db, "messages"), {
          message,
          buyer: buyer,
          url: attachmentUrl,
          ticketNumber,
          status: "open", // Initial status
          timestamp: new Date().toISOString(),
          sender: user?.username,
          targetDate,
          targetPerson
        });
        console.log("Document written with ID: ", docRef.id);
        setAttachmentUrl("");
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      setMessage("");
      setFilename("");
      toast.success("Message sent");
    }
  };

  const handleAttachmentUpload = async (event: any) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsAttachmentUpload(true);
      const url = await uploadDocumentWithName(file, file.name);
      setAttachmentUrl(url);
      setIsAttachmentUpload(false);
      setFilename(file.name);
    }
  };

  return (
    <div className="container mx-auto flex flex-col p-4">
      <textarea
        value={message}
        className="w-[400px] outline px-2 rounded-md"
        rows={4}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="send message to manager"
      />
      <div>
        <div className="flex flex-col max-w-[400px] gap-4 mt-3">
          <ReactSelect
            options={users}
            placeholder="Select a Buyer"
            isSearchable
            onChange={(value: SelectOption | null) =>
              setBuyer(value ? value.label : null)
            }
          />

          <TextField
            placeholder="Target Person"
            onChange={(e) => setTargetPerson(e.target.value)}
          />
          <TextField
            type="datetime-local"
            placeholder="Target Date and time"
            onChange={(e) => setTargetDate(e.target.value)}
          />

          {/* <ReactSelect
            className="max-w-[400px] my-3"
            options={KAMS}
            placeholder="Select a KAM"
            isSearchable
            onChange={(value: SelectOption | null) => setKam(value ? value.label : null)}
          /> */}
        </div>
      </div>
      <div className="flex lg:flex-row flex-col mt-2 relative gap-2">
        <Button
          variant="contained"
          onClick={() => document.getElementById("fileInput")?.click()}
        >
          {isAttachmentUpload ? "Uploading..." : "Upload Attachment"}
          <input
            type="file"
            id="fileInput"
            hidden
            onChange={handleAttachmentUpload}
          />
        </Button>
        <Button 
        disabled={targetPerson === "" || targetDate === "" || message === ""}
        onClick={handleSendMessage} variant="contained">
          Send
        </Button>
      </div>
      <span className="py-1">{filename}</span>
    </div>
  );
};

export default Admin;
