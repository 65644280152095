import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid2,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { uploadDocumentWithName, uploadImage } from "../../firebase";
import db from "../../firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  increment,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  fetchAndParseExcel,
  staticColumns,
  staticColumnsKAM,
  staticColumnsUser,
} from "../../utils";
import { getAuthFromLocal } from "../../utils/storage";
import { useDispatch } from "react-redux";
import { setOrderData } from "../../store/orderSlice";
import { toast } from "react-toastify";
import MessageTable from "./MessagesTable";

import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const DemoTable = () => {
  const [tableData, setTableData] = useState([]);
  const user = getAuthFromLocal();
  const [isLoading, setIsLoading] = useState(true);
  const [filterValue, setFilterValue] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [message, setMessage] = useState("");
  const [isAttachmentUpload, setIsAttachmentUpload] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [isRefresh, setRefresh] = useState(false);
  const [lastUpdate, setLastupdate] = useState(false);

  const [filteredData, setFilteredData] = useState(tableData);
  const [filterModel, setFilterModel] = useState({ items: [] });

  const dispatch = useDispatch();

  // Function to apply the filter manually
  const applyFilter = (model) => {
    let filteredRowData= filteredRows;

    model.items.forEach((filter) => {      
      if (filter.value) {
        filteredRowData = filteredRows.filter((row) => {
          const rowValue = row[filter.field]?.toString().toLowerCase();
          const filterValue = filter.value.toLowerCase();
        
          
          if (filter.operator === "contains") {
            return rowValue.includes(filterValue);
          } else if (filter.operator === "equals") {
            return rowValue === filterValue;
          } else if (filter.operator === "greaterThan") {
            return Number(rowValue) > Number(filterValue);
          } else if (filter.operator === "lessThan") {
            return Number(rowValue) < Number(filterValue);
          }
          return true;
        });
      }
    });
        
    setFilteredData(filteredRowData);
  };

  useEffect(() => {
    loadDataFromFirestore();
  }, []);

  const headerData = () => {
    switch (user?.role) {
      case "SUPER_ADMIN":
        return staticColumns;
      case "KAM":
        return staticColumnsKAM;
      case "USER":
        return staticColumnsUser;
      default:
        return staticColumns;
    }
  };

  const fetchDownloadURL = async () => {
    try {
      // Get the document containing the download URL
      const fileDocRef = doc(db, "files", "orderFile"); // Adjust path if needed
      const fileDoc = await getDoc(fileDocRef);

      if (fileDoc.exists()) {
        const data = fileDoc.data();
        return {
          url: data.url,
          timestamp: data?.timestamp,
        }; // Return the download URL
      } else {
        console.error("No file found in Firestore");
        return null;
      }
    } catch (error) {
      console.error("Error fetching download URL:", error);
      return null;
    }
  };

  const loadDataFromFirestore = async (obj) => {
    const downloadURL = obj ? obj : await fetchDownloadURL();
    
    if (downloadURL) {
      const jsonData = await fetchAndParseExcel(downloadURL?.url);
      setLastupdate(downloadURL?.timestamp);

      if (jsonData && jsonData.length > 1) {
        const bodyData = jsonData.slice(1);
        let rowsData = bodyData.map((row, index) => {
          const rowData = { id: index + 1 };

          jsonData[0].forEach((header, index) => {
            let value = row[index] || "";

            if (
              (header === "dispatchDate" || header === "orderDate") &&
              typeof value === "number" &&
              !isNaN(value) &&
              value > 0
            ) {
              const date = new Date((value - 25569) * 86400 * 1000);
              if (!isNaN(date.getTime())) {
                value = date.toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                });
              } else {
                value = "";
              }
            }

            if (
              header === "status" &&
              typeof value === "number" &&
              !isNaN(value) &&
              value > 0
            ) {
              const date = new Date((value - 25569) * 86400 * 1000);
              if (!isNaN(date.getTime())) {
                value = date.toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                });
              }
            }

            rowData[header] = value;
          });

          return rowData;
        });

        dispatch(setOrderData(rowsData));

        if (user && user.role === "KAM") {
          rowsData = rowsData.filter(
            (a) =>
              a.KAM &&
              a.KAM.toString().trim() === user.phoneNumber.toString().trim()
          );
        }
        if (user && user.role === "USER") {
          rowsData = rowsData.filter(
            (a) =>
              a.user &&
              a.buyerGroup.toString().trim() === user.username.toString().trim()
          );
        }

        setTableData(rowsData);
        setFilteredRows(rowsData);
        setIsLoading(false);
      } else {
        setFilterValue([]);
        setTableData([]); // Clear data if the file is empty or invalid
      }
    }
  };

  const handleExportToExcel = async () => {
    const data = filteredData.length > 0 ? filteredData : tableData;
    const dataWithoutStaticColumns = data.map(({ id, ...rest }) => rest);
    const worksheet = XLSX.utils.json_to_sheet(dataWithoutStaticColumns, {
      header: staticColumns.map((col) => col.field),
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert workbook to an array buffer (binary data)
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Create a Blob from the binary data and use FileSaver to trigger a download
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "orders.xlsx"); // This will trigger the download
  };

  const setDocToStoe = async (url) => {
    const fileDocRef = doc(db, "files", "orderFile"); // Adjust path and document ID as needed
    await setDoc(fileDocRef, {
      url: url,
      timestamp: new Date().toISOString(),
    });
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setTableData([]);
      setIsLoading(true);
      const url = await uploadImage(file);
      setDocToStoe(url);
      const obj = {
        url,
        timestamp: new Date().toISOString(),
      };
      loadDataFromFirestore(obj);
    }
  };

  const handleProcessRowUpdate = (newRow) => {
    const updatedRows = tableData.map((row) => {
      if (row.id === newRow.id) {
        return { ...row, ...newRow };
      }
      return row;
    });
    setTableData(updatedRows);
    return newRow;
  };

  const handleApplyFilter = () => {
    const filterNum = filterValue === "" ? 0 : Number(filterValue);
    if (isNaN(filterNum)) return;

    const filtered = tableData.filter((row) => {
      if (!row.balanceQuantity && row.balanceQuantity !== 0) return false;
      const balance = Number(row.balanceQuantity);
      return !isNaN(balance) && balance >= filterNum;
    });

    const sortedFiltered = filtered.sort(
      (a, b) => b.balanceQuantity - a.balanceQuantity
    );
    setFilteredRows(sortedFiltered);
  };

  const handleClearFilter = () => {
    setFilterValue("");
    setFilteredRows(tableData);
  };

  const generateTicketNumber = async () => {
    try {
      const counterRef = doc(db, "counters", "ticketNumber");
      const counterSnap = await getDoc(counterRef);

      if (counterSnap.exists()) {
        // Increment the counter atomically
        const currentNumber = counterSnap.data().current || 0;
        const newNumber = currentNumber + 1;

        // Update the counter in Firestore
        await updateDoc(counterRef, { current: increment(1) });

        // Format the ticket number (e.g., T-0001, T-0002)
        const formattedTicket = `T-${newNumber.toString().padStart(4, "0")}`;
        return formattedTicket;
      } else {
        // Initialize the counter if it doesn't exist
        await setDoc(counterRef, { current: 1 });
        return "T-0001";
      }
    } catch (error) {
      console.error("Error generating ticket number:", error);
      throw new Error("Failed to generate ticket number.");
    }
  };

  const handleSendMessage = async () => {
    if (message) {
      const ticketNumber = await generateTicketNumber();
      try {
        const docRef = await addDoc(collection(db, "messages"), {
          message,
          buyer: user?.username,
          url: attachmentUrl,
          ticketNumber,
          status: "open", // Initial status
          timestamp: new Date().toISOString(),
        });
        console.log("Document written with ID: ", docRef.id);
        setAttachmentUrl("");
        setRefresh(true);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      setMessage("");
      toast.success("Message sent");
    }
  };

  const handleAttachmentUpload = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsAttachmentUpload(true);
      const url = await uploadDocumentWithName(file, file.name);
      setAttachmentUrl(url);
      setIsAttachmentUpload(false);
    }
  };

  return (
    <div className="p-4 bg-white flex flex-col h-screen mx-auto container">
      <Grid2>
        <Grid2>
          {user && user.role === "USER" && (
            <div className="flex md:hidden gap-2 flex-col lg:flex-row">
              <Card variant="outlined" className="relative">
                <div className="absolute w-[10px] h-[60px] bg-red-500 right-0" />
                <CardContent>
                  <Typography
                    variant="span"
                    component="div"
                    color="red"
                    className="text-3xl font-bold"
                  >
                    ₹{user?.outstanding} /-
                  </Typography>
                  <Typography
                    gutterBottom
                    sx={{ color: "text.secondary", fontSize: 14 }}
                  >
                    Outstanding due on date
                  </Typography>
                  <Typography variant="h5" component="div">
                    {user?.dueDate}
                  </Typography>
                </CardContent>
              </Card>
              <Card variant="outlined" className="flex relative">
                <div className="absolute w-[10px] h-[50px] bg-blue-600 right-0" />
                <CardContent>
                  <Typography sx={{ color: "text.secondary" }}>
                    Message
                  </Typography>
                  <Typography
                    variant="span"
                    component="div"
                    className="font-semibold"
                  >
                    {user?.message}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          )}
        </Grid2>
      </Grid2>
      <div className="flex flex-col lg:flex-row w-full pb-2 lg:gap-2">
        {user && user.role === "USER" && (
          <div className="hidden lg:flex flex-1  gap-2 flex-col  lg:flex-row">
            <Card variant="outlined" className="relative min-w-[300px]">
              <div className="absolute w-[10px] h-[60px] bg-red-500 right-0" />
              <CardContent>
                <Typography
                  variant="span"
                  component="div"
                  color="red"
                  className="text-3xl font-bold"
                >
                  ₹{user?.outstanding} /-
                </Typography>
                <Typography
                  gutterBottom
                  sx={{ color: "text.secondary", fontSize: 14 }}
                >
                  Outstanding due on date
                </Typography>
                <Typography variant="h5" component="div">
                  {user?.dueDate}
                </Typography>
              </CardContent>
            </Card>
            <Card
              variant="outlined"
              className="relative max-w-[200px] min-w-min lg:min-w-[400px]"
            >
              <div className="absolute w-[10px] h-[60px] bg-blue-600 right-0" />
              <CardContent>
                <Typography sx={{ color: "text.secondary" }}>
                  Message
                </Typography>
                <Typography variant="h6" component="div">
                  {user?.message}
                </Typography>
              </CardContent>
            </Card>
          </div>
        )}

        <div className="flex flex-col lg:flex-row lg:flex-1 gap-2 items-end">
          {(user?.role === "SUPER_ADMIN" || user?.role === "HEAD") && (
            <Button variant="contained" component="label">
              Upload File
              <input
                type="file"
                hidden
                onChange={handleFileUpload}
                accept=".xlsx, .xls"
              />
            </Button>
          )}
          {tableData.length !== 0 && user?.role !== "USER" && (
            <Button
              sx={{ height: "36px" }}
              variant="contained"
              color="primary"
              onClick={handleExportToExcel}
            >
              Export to Excel
            </Button>
          )}
          {lastUpdate && user?.role !== "USER" && (
            <span className="text-red-500">
              Last update:{" "}
              {new Date(lastUpdate).toLocaleString(undefined, {
                dateStyle: "medium", // Displays the date (e.g., MM/DD/YYYY)
                timeStyle: "short", // Displays the time (e.g., 10:45 PM)
              })}
            </span>
          )}
        </div>
        <div className="flex flex-col justify-between mt-6 lg:mt-0 gap-4 lg:items-end">
          {user?.role === "USER" && (
            <div>
              <div className="flex lg:flex-row flex-col mt-4 relative gap-2">
                <textarea
                  value={message}
                  size="small"
                  className="w-[300px] outline px-2 rounded-md"
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="send message to manager"
                />
                <Button
                  variant="contained"
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  {isAttachmentUpload ? "Uploading..." : "Upload Attachment"}
                  <input
                    type="file"
                    id="fileInput"
                    hidden
                    onChange={handleAttachmentUpload}
                  />
                </Button>
                <Button onClick={handleSendMessage} variant="contained">
                  Send
                </Button>
              </div>
            </div>
          )}
          <div className="flex flex-col lg:flex-row gap-2">
            <TextField
              label="Balance Quantity greater than"
              variant="outlined"
              value={filterValue}
              size="small"
              onChange={(e) => setFilterValue(e.target.value)}
              type="number"
              inputProps={{ min: "0" }}
            />
            <div className="flex justify-between h-[40px]">
              <Button variant="contained" onClick={handleApplyFilter}>
                Apply Filter
              </Button>
              <Button
                variant="outlined"
                onClick={handleClearFilter}
                sx={{ marginLeft: { xs: "5px", sm: 1, md: 1 } }}
              >
                Clear Filter
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Paper style={{ marginTop: "20px" }}>
        <Box
          sx={{
            height: 600,
            width: "100%",
          }}
        >
          <DataGrid
            pageSizeOptions={[5, 10, 25, { value: -1, label: "All" }]}
            isCellEditable={() => user?.role === "SUPER_ADMIN"}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              columns: {
                columnVisibilityModel: {
                  user: false,
                  KAM: false,
                  superadmin: false,
                },
              },
              sorting: {
                sortModel: [
                  {
                    field: user?.role === "USER" ? "brand" : "buyerGroup",
                    sort: "asc",
                  },
                ],
              },
            }}
            filterModel={filterModel}
            onFilterModelChange={(model) => {
              setFilterModel(model);
              applyFilter(model);
            }}
            rows={filteredRows}
            columns={headerData()}
            getRowId={(row) => row.id}
            processRowUpdate={handleProcessRowUpdate}
            loading={isLoading}
            sx={{
              "& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader": {
                backgroundColor: "#566270",
                color: "white",
                fontSize: "16px",
                fontWeight: "bold !important",
                fontFamily: "Arial, sans-serif !important",

                // Adjust as needed
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold !important",
                fontFamily: "Arial, sans-serif !important", // Apply font family to the header titles
              },
            }}
          />
        </Box>
      </Paper>
      {user?.role === "USER" && (
        <MessageTable
          isRefresh={isRefresh}
          setRefresh={() => setRefresh(false)}
        />
      )}
    </div>
  );
};

export default DemoTable;
